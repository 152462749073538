<template>
  <div class="dataTables_wrapper no-footer bg-white">
    <div class="pull-right">
      <button @click="exportData" class="btn btn-default">
        CSV出力
      </button>
    </div>
    <div class="table-responsive">
      <div class="dataTables_info p-0 app-text text-complete" style="margin-top: 0 !important;" role="status"
           aria-live="polite">
        全{{ meta.total }}件中 {{ meta.from }}件 〜 {{
          meta.to
        }}件 を表示
      </div>
      <table
        :class="[entries.length ? '' : 'table-empty-data', 'table dataTable app-table no-footer']">
        <thead>
        <tr>
          <th class="text-center cell-fit-content">
            No.
          </th>
          <template v-for="column in columns">
            <th :class="[ 'text-center', column.fitSize ? 'cell-fit-content' : '']">
              {{ column.label }}
            </th>
          </template>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!entries.length">
          <td colspan="100">
                  <span v-if="loading">
                    {{ $t('common.loading') }}
                  </span>
            <span v-else>
                    {{ $t('common.list empty') }}
                  </span>
          </td>
        </tr>
        <tr v-for="entry in entries">
          <td class="app-table-p v-align-middle text-center">
            {{ entry.no }}
          </td>
          <td v-for="column in columns" :class="['v-align-middle', column.class]">
            <p :data-original-title="column.computed ? column.computed(entry) : _.get(entry, column.name)"
               class="app-table-p app-cell-tooltip">
              <span v-html="column.computed ? column.computed(entry) : _.get(entry, column.name)"></span>
            </p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="d-flex justify-content-between">
        <div class="dataTables_paginate app-paginate-container">
          <AppPaginate
            v-if="parseInt(meta.last_page) > 1"
            ref="paginate"
            :page-count="parseInt(meta.last_page)"
            :page-range="5"
            :margin-pages="2"
            :click-handler="clickPagination"
            prev-text="<"
            next-text=">"
            breakViewClass="paginate-break"
            prev-class="paginate-button-prev"
            next-class="paginate-button-next"
            :force-page="parseInt(pagination.currentPage)"
            :hide-prev-next="true"
            :page-class="'paginate-button'">
          </AppPaginate>
        </div>
        <div class="d-flex justify-content-start padding-20 form-group dataTable_info_custom">
          <div class="m-r-5 m-t-5 perpage-label"> {{ $t('common.perpage') }}
          </div>
          <div class="form-group">
            <select class="form-control" @change="changePerPage"
                    v-model="pagination.currentPerPage">
              <option v-for="perpage in pagination.perPages" :value="perpage">
                {{ perpage }}
              </option>
            </select></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: [
    'filters'
  ],
  data() {
    return {
      entries: [],
      pagination: {
        currentPage: 1,
        currentPerPage: 10,
        perPages: [5, 10, 25, 50, 100, 250, 500],
      },
      meta: {},
      loading: true,
      columns: [
        {name: 'name', label: 'ホテル名'},
        {
          name: 'booking_num',
          label: '件数',
          class: 'text-center',
          computed: entry => this.formatNumber(entry.booking_num)
        },
        {name: 'people_num', label: '人数', class: 'text-center', computed: entry => this.formatNumber(entry.people_num)},
        {
          name: 'total_price',
          label: 'パック料金',
          class: 'text-right',
          computed: entry => this.formatNumber(entry.total_price) + '円'
        },
      ],
    }
  },
  watch: {
    "$route.query": {
      handler: function (after, before) {
        if (!_.isEqual(after, before)) {
          this.getEntries()
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.getEntries()
  },
  methods: {
    clickPagination(selectPage) {
      if (selectPage !== this.pagination.currentPage) {
        this.setCurrentPage(selectPage)
        this.getEntries()
      }
    },
    changePerPage() {
      this.setCurrentPage(1)
      this.getEntries()
    },
    setCurrentPage(page) {
      this.pagination.currentPage = page;
      if (this.$refs.paginate) {
        this.$refs.paginate.handlePageSelected(page);
      }
    },
    async getEntries() {
      const {currentPage, currentPerPage} = this.pagination
      let params = {
        page: currentPage,
        perPage: currentPerPage,
      }
      _.forEach(this.filters, (filter) => {
        if (filter.value && filter.value.toString().length) {
          params[`filters[${filter.name}][${filter.type}]`] = filter.value
        }
      })

      const res = await this.$request.get(this.$consts.API.ADMIN.ANALYSIS_BY_HOTEL, {...params})
      if (!res.hasErrors()) {
        this.entries = res.data.data.map((entry, index) => {
          return {
            ...entry, no: (index + 1 + (this.pagination.currentPage - 1) * this.pagination.currentPerPage)
          }
        })
        this.meta = res.data.meta
        if (!this.meta.from) {
          this.meta.from = 0;
        }
        if (!this.meta.to) {
          this.meta.to = 0;
        }
      }
      if (this.pagination.currentPage > 1 && this.entries.length === 0) {
        this.setCurrentPage(1)
        this.getEntries()
      }
      this.$nextTick(() => {
        $.each($('.app-cell-tooltip'), (index, el) => {
          if (el.offsetWidth < el.scrollWidth) {
            $(el).tooltip({boundary: 'window'})
          }
        })
      });
      this.loading = false;
    },
    exportData() {
      var params = {}
      _.forEach(this.filters, (filter) => {
        if (filter.value && filter.value.toString().length) {
          params[`filters[${filter.name}][${filter.type}]`] = filter.value
        }
      })
      this.$request.get(this.$consts.API.ADMIN.ANALYSIS_EXPORT_HOTEL, {...params}).then(res => {
        if (!res.hasErrors()) {
          this.downloadFromUrl(res.data.url)
        } else {
          this.$appNotice.error();
        }
      })
    }
  }
};
</script>
